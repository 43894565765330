<template>
	<v-container
			fluid
	>
		<v-row justify="center">
			<v-col cols="12">
				<v-card>
					<v-card-text>
						<v-data-table
								:loading="loading"
								:headers="headers"
								:items="items"
								:server-items-length="totalItems"
								item-key="id"
								:search="search"
								:options.sync="options"
								class="row-pointer"
								@click:row="goToPost"
								:footer-props="{'items-per-page-options':[10,25,50]}"
						>
							<template v-slot:top>
								<v-row no-gutters>
									<v-col cols="12">
										<v-text-field no-gutters clearable v-model="search" label="Search" class="ps-4 pt-6 pr-4"></v-text-field>
									</v-col>
								</v-row>
							</template>
							<template v-slot:[`item.author`]="{ item }">
								<span class="text-no-wrap">{{ item.author.display_name }}</span>
							</template>
						</v-data-table>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	export default {
		data: () => ({
			loading: false,
			search: '',
			options: {
				sortDesc: [true],
				sortBy: [`date_created`],
			},
			headers: [
				{ text: `Author`, value: `author`, sortable: false },
				{ text: `Message`, value: `text`, sortable: false },
				{ text: `Created`, value: `date_created`, width: `190px` },
			],
			totalItems: 0,
			items: [],
		}),
		methods: {
			goToPost(value) {
				this.$router.push({name: `Post`, params: {id: value.post_id}, query: {comment: value.id}})
			},
			refreshContent () {
				this.loading = true
				this.$httpInt.get(`/v2/app/comments/`, { params: this.$paginationParams(this) })
					.then(res => {
						this.items = res.data.result
						this.totalItems = res.data.pagination.total
					})
					.finally(() => this.loading = false)
			},
		},
		name: `CommunityComments`,
		watch: {
			search: {
				handler (value) {
					if (value && value.length < 4) return
					this.$debounce(() => this.refreshContent(), 300)
				},
				deep: true,
			},
			options: {
				handler () {
					this.refreshContent()
				},
				deep: true,
			},
		},
	}
</script>
